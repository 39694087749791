
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiMixin.js';
import moment from 'moment';

import axios from 'axios';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'finman-invoice',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      load: false,
      componentKey: 0,
      alertText: false,
      role_title: [] as any,
      actionCreate: false,
      createTrainer: false,
      text: '',
      file_name: '',
      cash_switch: 'no',
      bank_switch: 'no',
      tahir: false,
      customer_address:'',
      product: {
        id: '',
        invoice_number: '',
        dob: '',
        so: '',
        do: '',
        due_date: '',
        customer_id: '',
        customer_ledger_id: '',
        salesman_id: '',
        cash: '',
        bank: '',
        transaction_id: '',
        user_id:'' as any,
      } as any,
      updateButton: false,
      customerList: [] as any,
      salesmanList: [] as any,
      professional_details: [
        {
          designation: '',
          organization: '',
          address: '',
          exp_from: '',
          exp_to: '',
        },
      ] as any,
      product_details: {
        id: '',
        category_name: '',
        product_name: '',
        product_model: '',
        product_model_id: '',
        product_color: '',
        quantity: '',
        unit_price: '',
        total_price:'',
        sd_percentage: '',
        vat_percentage: '',
        sd_amount: '',
        vat_amount: '',
        net_total: '',
        product_category_id: '',
        inventory_ledger_id: '',
        vat_ledger_id:'',
        cgs_ledger_id: '',
        revenue_ledger_id: '',
        old_revenue_ledger_id: '',
        category_id: '',
        product_id: '',
        vds_amount: '',
        size_unit: '',
        size_measurement:'',
        weight_unit:'',
        weight_measurement:'',
        product_info: [] as any,
      } as any,
      url: '',
      tranche: [],
      entityRoles: [],
      courseList: [],
      courseInfoData: false,
      entityInfos: [],
      productlistData: [] as any,
      institutes: [],
      categoryList: [] as any,
      nidData: {},
      loading: false,
      showTrainerInfo: false,
      showBankLegacy: false,
      product_details_data: [] as any,
      editItemIndex: '' as any,
      // finmanUserId: '' as any,
      finmanUserId: '' as any,
      finmantoken: '' as any,
      vat_total:0 as any,
      grand_total:0 as any,
      value_total: 0 as any,
      invoice: {} as any,
      invoice_details: [] as any,
      viewEditForm: false,
    };
  },

  async created() {
      
    let user_id = VueCookieNext.getCookie('user_id');
    this.product.user_id= parseInt(user_id);
    await this.getCategoryData();
    await this.getCustomerList();
    await this.getSalesmanList();
    await this.setInvoiceData();
    this.viewEditForm=false;
  },
  methods: {
    async setInvoiceData()
    {
      let invoice_id = this.$route.query.id
      ApiService.get("finmanInvoice/invoice/getInvoiceDetails/"+ `${invoice_id}`)
        .then((response) => {
          let invoice= response.data.data.invoice;
          this.product.id= invoice.id;
          this.product.invoice_number= invoice.invoice_no;
          this.product.dob= invoice.date;
          this.product.so= invoice.so_number;
          this.product.do= invoice.do_number;
          this.product.due_date= invoice.due_date;
          this.product.customer_id= invoice.customer_id;
          this.setCustomerData();
          // this.product.salesman_id= invoice.salesman_id;
          this.product.transaction_id= invoice.bank_transaction_number;
          
          response.data.data.invoice_details.forEach((invoice_detail) => {
            this.product_details_data.push(JSON.parse(JSON.stringify(invoice_detail)));
          });
          this.calculateTotal();
          
        }).catch(({ response }) => {

        });
      // this.product.invoice_number=invoiceData.invoice.invoice_no;
    },
    saveInvoiceDate()
    {
      VueCookieNext.setCookie('sale_date_'+this.finmanUserId, this.product.dob);
    },
    async getInvoiceNumber() {
      await ApiService.get(
        'finmanInvoice/getInvoiceNumber?finman_user_id=' + this.finmanUserId
      )
        .then((response) => {
          this.product.invoice_number = response.data.data;
        })
        .catch(({ response }) => {
          this.load = false;
        });
    },
    setCustomerData()
    {
      let customer= this.customerList.find(element => element.id == this.product.customer_id);
      this.customer_address= customer.address;
      this.product.customer_ledger_id= customer.finman_ledger_id;
    },
    updateProduct() {
      this.product_details_data[this.editItemIndex].product_info_id =
      this.product_details.product_info;

      let product= this.productlistData.find(element => element.id == this.product_details.product_info);
      this.product_details_data[this.editItemIndex].product_name = product.product_name;

      // this.product_details_data[this.editItemIndex].product_name= this.product_details.product_name;
      // this.product_details_data[this.editItemIndex].product_model;
      // this.product_details_data[this.editItemIndex].product_color;
      // this.product_details_data[this.editItemIndex].size_measurement;
      // this.product_details_data[this.editItemIndex].size_unit;
      // this.product_details_data[this.editItemIndex].weight_measurement;
      // this.product_details_data[this.editItemIndex].weight_unit;

      this.product_details_data[this.editItemIndex].quantity =
        this.product_details.quantity;
      this.product_details_data[this.editItemIndex].unit_price =
        this.product_details.unit_price;
      // this.product_details_data[this.editItemIndex].sd_amount =
      //   this.product_details.sd_amount;
      // this.product_details_data[this.editItemIndex].sd_percentage =
      //   this.product_details.sd_percentage;
      this.product_details_data[this.editItemIndex].vat_percentage =
        this.product_details.vat_percentage;
      this.product_details_data[this.editItemIndex].vat_amount =
        this.product_details.vat_amount;
      this.product_details_data[this.editItemIndex].net_total =
        this.product_details.net_total;
      this.product_details_data[this.editItemIndex].vds_amount =
        this.product_details.vds_amount;
      
      let category= this.categoryList.find(element => element.id == this.product_details.product_category_id);
      this.product_details_data[this.editItemIndex].category_name = category.category_name;
      this.product_details_data[this.editItemIndex].product_category_id = this.product_details.product_category_id;
      // this.product_details_data[this.editItemIndex].old_revenue_ledger_id= this.product_details.old_revenue_ledger_id;
      this.product_details_data[this.editItemIndex].revenue_ledger_id= this.product_details.revenue_ledger_id;
      this.product_details_data[this.editItemIndex].vat_ledger_id= this.product_details.vat_ledger_id;
      
      
      
      // field clear and make index empty again and add product visible
      this.product_details.product_info = '';
      this.product_details.product_category_id = '';
      // this.product_details.product_model_id = '';
      this.product_details.unit_price = '';
      // this.product_details.sd_percentage = '';
      this.product_details.vat_percentage = '';
      // this.product_details.sd_amount = '';
      this.product_details.vat_amount = '';
      this.product_details.vds_amount = '';
      this.product_details.quantity = '';
      this.product_details.net_total = '';
      this.product_details.total_price= '';

      this.editItemIndex = '' as any;
      this.updateButton = false;
      this.calculateTotal();
    },
    editProduct(data, index) {
      const section = document.getElementById('editForm');
      if (section instanceof HTMLElement && section !== null) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
      // set the data first
      this.editItemIndex = index;

      this.product_details.product_category_id= data.product_category_id;
      this.productlist(data.product_category_id)
      this.product_details.product_info= data.product_info_id;
      this.product_details.quantity= data.quantity;
      this.product_details.unit_price= data.unit_price;
      this.product_details.total_price= Number(data.quantity* data.unit_price);
      this.product_details.net_total= data.net_total;
      this.product_details.vat_amount= data.vat_amount;
      this.product_details.vds_amount= data.vds_amount;
      this.product_details.vat_percentage= data.vat_percentage;
      // this.product_details.old_revenue_ledger_id = data.revenue_ledger_id;
      this.product_details.revenue_ledger_id = data.revenue_ledger_id;
      // this.product_details.old_vat_ledger_id = data.vat_ledger_id;
      this.product_details.vat_ledger_id = data.vat_ledger_id;

      // this.product_details.unit_price = data.unit_price;
      // this.product_details.sd_percentage = data.sd_percentage;
      // this.product_details.vat_percentage = data.vat_percentage;
      // this.product_details.sd_amount = data.sd_amount;
      // this.product_details.vat_amount = data.vat_amount;
      // this.product_details.net_total = data.net_total;
      // this.product_details.vds_amount = data.vds_amount;
      // enable edit bTn
      this.updateButton = true;
    },
    async calculateData() {
      // update sd amount and vat amount
      // this.product_details.sd_amount =
      //   Number(
      //     this.product_details.sd_percentage * this.product_details.product_info.unit_price * this.product_details.quantity
      //   ) / 100;
      let quantity=0;
      quantity= Number(this.product_details.quantity)>0? this.product_details.quantity: 1;
      this.product_details.total_price= quantity * this.product_details.unit_price;
      this.product_details.vat_amount =
        Number(
          this.product_details.vat_percentage * this.product_details.unit_price * quantity
        ) / 100;

      // this.product_details.vds_amount =
      //   Number(
      //     this.product_details.vds_amount * quantity
      //   );
      // calculate net total
      this.product_details.net_total =
        Number(quantity * this.product_details.unit_price) +
          Number(this.product_details.vat_amount) +
          Number(this.product_details.vds_amount) ;
      
      this.product_details.net_total =
        Number(quantity * this.product_details.unit_price) +
          Number(this.product_details.vat_amount) +
          Number(this.product_details.vds_amount) ;
      //
    },
    async getCategoryData() {
      await ApiService.get('finmanInvoice/category/list?type=1')
        .then((response) => {
          this.categoryList = response.data.data;

          this.componentKey += 1;
          this.load = true;
        })
        .catch(({ response }) => {
          this.load = false;
        });
    },
    async getCustomerList() {
      await ApiService.get('finmanInvoice/customer_list')
        .then((response) => {
          this.customerList = response.data.data;
          this.componentKey += 1;
          this.load = true;
        })
        .catch(({ response }) => {
          this.load = false;
        });
    },
    async getSalesmanList() {
      await ApiService.get('finmanInvoice/salesman/list')
        .then((response) => {
          this.salesmanList = response.data.data;
          this.componentKey += 1;
          this.load = true;
        })
        .catch(({ response }) => {
          this.load = false;
        });
    },
    async productlist(category_id) {
      this.product_details.category_id =
        this.product_details.product_category_id;
      // this.product_details.category_name =
      //   this.product_details.product_category_id.name;

      this.product_details.unit_price = '';
      this.product_details.sd_percentage = '';
      this.product_details.vat_percentage = '';
      this.product_details.sd_amount = '';
      this.product_details.vat_amount = '';
      this.product_details.vds_amount = '';
      this.product_details.quantity = '';
      this.product_details.net_total = '';
      this.product_details.product_model_id = '';
      await ApiService.get(
        'finmanInvoice/product/listForInvoice?category_id=' + category_id
      )
        .then((response) => {
          this.productlistData = response.data.data;

          // for (let i = 0; i < response.data.data.length; i++) {
          //   this.productlistData.push({
          //     id: response.data.data[i].id,
          //     name: `${response.data.data[i].product_name}-${response.data.data[i].brand}-${response.data.data[i].unit_price}`,
          //   });
          // }
          // this.componentKey += 1;
          this.load = true;
        })
        .catch(({ response }) => {
          this.load = false;
        });
    },
    async getProductInfo(product_info_id) {
      this.product_details.product_id =
        this.product_details.product_model_id.id;
      this.product_details.product_name =
        this.product_details.product_model_id.name;
      this.product_details.sd_amount = '';
      this.product_details.vat_amount = '';
      this.product_details.unit_price = '';
      this.product_details.sd_percentage = '';
      this.product_details.vat_percentage = '';
      this.product_details.vds_amount = '';
      let product_id = this.product_details.product_id;

      let product=  this.productlistData.find(obj => obj.id === product_info_id);

      this.product_details.product_name = product.product_name;
      this.product_details.product_model = product.model;
      this.product_details.product_color = product.color;
        // this.product_details.measurement =
        // this.product_details.product_info.measurement;
      this.product_details.size_measurement = product.size_measurement;
      this.product_details.weight_measurement = product.weight_measurement;
      this.product_details.size_unit = product.size_unit;
      this.product_details.weight_unit = product.weight_unit;
      this.product_details.unit_price = product.unit_price;
      this.product_details.total_price = product.unit_price* this.product_details.quantity;
      this.product_details.vat_percentage = product.vat_percent;
      this.product_details.vat_amount = (product.vat_percent * product.unit_price * this.product_details.quantity) / 100;
      this.product_details.vds_amount = product.vds_amount;
      this.product_details.net_total= Number(this.product_details.total_price+this.product_details.vat_amount+this.product_details.vds_amount);
      this.product_details.info_id = product.id;
      // this.product_details.inventory_ledger_id = product.inventory_ledger_id;
      this.product_details.vat_ledger_id = product.vat_ledger_id;
      // this.product_details.cgs_ledger_id = this.product_details.product_info.cgs_ledger_id;
      this.product_details.revenue_ledger_id = product.revenue_ledger_id;

      // await ApiService.get('finmanInvoice/product/show/' + product_id)
      //   .then((response) => {
      //     this.product_details.unit_price = response.data.data.unit_price;
      //     this.product_details.sd_percentage = response.data.data.sd_percentage;
      //     this.product_details.inventory_ledger_id =
      //       response.data.data.inventory_ledger_id;
      //     this.product_details.vat_percentage =
      //       response.data.data.vat_percentage;
      //     this.product_details.sd_amount =
      //       (response.data.data.sd_percentage * response.data.data.unit_price) /
      //       100;
      //     this.product_details.vat_amount =
      //       (response.data.data.vat_percentage *
      //         response.data.data.unit_price) /
      //       100;
      //     this.product_details.vds_amount = response.data.data.vds_amount;

      //

      //     this.componentKey += 1;
      //     this.load = true;
      //   })
      //   .catch(({ response }) => {
      //     this.load = false;
      //   });
    },
    addProduct() {
      // clear
      let category= this.categoryList.find(element => element.id == this.product_details.product_category_id);
      this.product_details.category_name = category.category_name;
      console.log(this.product_details);
      this.product_details_data.push(
        JSON.parse(JSON.stringify(this.product_details))
      );
      this.product_details.quantity = '';
      this.product_details.product_category_id = '';
      this.product_details.product_id = '';
      this.product_details.product_model_id = '';
      this.productlistData = [] as any;
      this.product_details.unit_price = '';
      this.product_details.sd_percentage = '';
      this.product_details.vat_percentage = '';
      this.product_details.sd_amount = '';
      this.product_details.vat_amount = '';
      this.product_details.net_total = '';
      this.product_details.vds_amount = '';
      console.log(this.product_details_data);
      this.calculateTotal();
    },
    removeProduct(data) {
      const productitemIndex = this.product_details_data.indexOf(data);
      if(this.product_details_data.length==1)
      {
        return alert("You cannot remove this product from cart as this is the last item in edit mode");
      }
      if (productitemIndex > -1) {
        // only splice array when item is found
        this.product_details_data.splice(productitemIndex, 1); // 2nd parameter means remove one item only
      }
      // filed clear and make index empty again and add product visable
      this.product_details.product_category_id = '';
      this.product_details.product_model_id = '';
      this.product_details.unit_price = '';
      this.product_details.sd_percentage = '';
      this.product_details.vat_percentage = '';
      this.product_details.sd_amount = '';
      this.product_details.vat_amount = '';
      this.product_details.vds_amount = '';
      this.product_details.quantity = '';
      this.product_details.net_total = '';
      this.editItemIndex = '' as any;
      this.updateButton = false;
    },

    bank_change(e) {
      if (e.target.checked) {
        this.product.bank = '1';
        this.showBankLegacy = true;
        this.product.transaction_id = this.product.transaction_id;
        this.product.cash = '0';
        this.cash_switch = 'no';
      } else {
        this.product.bank = '0';
        this.showBankLegacy = false;
      }
    },
    cashChange(e) {
      if (e.target.checked) {
        this.product.cash = '1';
        this.product.bank = '0';
        this.product.transaction_id = '' as any;
        this.showBankLegacy = false;
        this.bank_switch = 'no';
      } else {
        this.product.cash = '0';
      }
    },

    calculateTotal()
    {
      const sum = this.product_details_data.reduce(
            (acc, cur) => {
              acc.vat_total += cur.vat_amount;
              acc.grand_total += cur.net_total;
              acc.value_total += (cur.quantity* cur.unit_price);
              return acc;
            },
            {
              vat_total: 0,
              grand_total: 0,
              value_total: 0,
            }
          );
      this.grand_total= sum.grand_total;
      this.vat_total= sum.vat_total;
      this.value_total= sum.value_total;
    },

    async formSubmit() {
      let formData = new FormData();

      for (var key in this.product) {
        formData.set(key, this.product[key]);
      }
      formData.set(
        'product_details',
        JSON.stringify(this.product_details_data)
      );
      formData.set('finmanUserId', this.finmanUserId);
      this.loading = true;
      const grandtotal = this.product_details_data.reduce(
        (accumulator, object) => {
          return accumulator + object.net_total;
        },
        0
      );
      formData.set('grand_total', grandtotal);

      await ApiService.post('finmanInvoice/editSale', formData)
        .then((response) => {
          this.loading = false;
          if (response.status == 200 && response?.data?.status == 'success') {
            Swal.fire({
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.product = {
                invoice_number: '',
                dob: '',
                so: '',
                customer_id: '',
                cash: '',
                bank: '',
                transaction_id: '',
              };
              this.product_details_data = [] as any;
              this.$router.push('/sales-invoices');
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: response.data.errors,
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html:
              response?.data?.error ||
              'Unknown error occured. Please check all the required field',
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
        });
    },
  },
});
